import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Layout from './shared/Layout';

const PrivateRoute = ({ element: Element, ...rest }) => {
    const { isAuthenticated, loading } = useAuth();
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    return isAuthenticated ? (
      <Routes>
        <Route {...rest} element={<Layout />} />
      </Routes>
    ) : (
      <Navigate to="/login" replace />
    );
  };
  
  export default PrivateRoute;
  
