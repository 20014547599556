import React, { useEffect, useState } from "react";
import axios from "axios";
import apiURL from "../config";

function LatestTranscations() {
  const [receipts, setReceipts] = useState([]);

  useEffect(() => {
    fetchReceipts();
  }, []);

  const fetchReceipts = async () => {
    try {
      const response = await axios.get(
        apiURL + `/api/get-receipts?page=${1}&limit=3`
      );
      setReceipts(response.data); // Set fetched receipts
    } catch (error) {
      console.error("Error fetching receipts:", error);
    }
  };

  return (
    <div className="bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1">
      <strong className="text-gray-700 font-medium">Latest Transcations</strong>
      <div className="mt-3">
        <table className="table-auto w-full border-collapse mt-5">
          <thead>
            <tr>
              <th className="px-4 py-2 bg-gray-800 text-white text-center">
                Invoice Number
              </th>
              <th className="px-4 py-2 bg-gray-800 text-white text-center">
                Rep Number
              </th>
              <th className="px-4 py-2 bg-gray-800 text-white text-center">
                Payment Type
              </th>
              <th className="px-4 py-2 bg-gray-800 text-white text-center">
                Total Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {receipts.map((receipt, index) => (
              <tr key={index} className="hover:bg-gray-200">
                <td className="border px-4 py-2 text-center">
                  {receipt.invoice_number}
                </td>
                <td className="border px-4 py-2 text-center">
                  {receipt.rep_id}
                </td>
                <td className="border px-4 py-2 text-center">
                  {receipt.payment_method}
                </td>
                <td className="border px-4 py-2 text-center">
                  Rs.{" "}
                  {receipt.total_payment.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default LatestTranscations;
