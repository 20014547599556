import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Logo from "../assets/logo.png";
import BackgroundImage from "../assets/cover1.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import apiURL from "../config";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await login(username, password);
      setTimeout(() => {
        setLoading(false);
        navigate("/");
      }, 3000); // 3000 milliseconds = 3 seconds
    } catch (error) {
      setLoading(false);
      toast.error("Login failed. Please check your credentials and try again.");
    }
  };

  const handleForgotPassword = async () => {
    if (username === "") {
      toast.error("Enter username!");
      return;
    }

    try {
      const response = await fetch(apiURL + "/api/admin-password-reset", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username }),
      });
      const data = await response.json();

      if (data.success) {
        toast.success("OTP sent to your registered contact number.");
      } else {
        toast.error(data.message || "Failed to send OTP.");
      }
    } catch (error) {
      toast.error("Failed to send OTP. Please try again.");
    }
  };

  return (
    <div
      className="min-h-screen bg-gray-100 flex justify-center items-center relative "
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <ToastContainer position="bottom-right" theme="dark" />
      <div className="bg-white p-8 rounded shadow-md w-80">
        <img src={Logo} alt="Description" className="px-9 pb-6" />
        <h2 className="text-2xl font-semibold mb-6">Admin Login</h2>
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <ThreeDots color="#4A90E2" height={80} width={80} />
          </div>
        ) : (
          <form onSubmit={handleLogin} className="space-y-4">
            <div>
              <label
                htmlFor="username"
                className="block text-md font-medium text-gray-700 "
              >
                Username
              </label>
              <input
                type="username"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                className="pl-2 mt-3 pt-2 pb-2 block w-full  border-gray-300 rounded-md shadow-md  focus:outline-none border-b"
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-md font-medium text-gray-700 "
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="pl-2 mt-3 pt-2 pb-2 block w-full  border-gray-300 rounded-md shadow-md  focus:outline-none border-b"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-indigo-500 text-white py-2 rounded-md hover:bg-indigo-600 transition duration-300"
            >
              Login
            </button>
          </form>
        )}

        <div className="mt-4 text-center">
          <button className="text-sm text-indigo-500 hover:text-indigo-600 transition duration-300" onClick={handleForgotPassword}> Forgot password?</button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
