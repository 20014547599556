import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext"; // Import useAuth
import { TbCircleLetterMFilled,TbCircleLetterSFilled,TbCircleLetterRFilled } from "react-icons/tb";
import { decrypt } from "../../utils/encryptionUtils";

export default function Header() {
  const navigate = useNavigate();
  const { logout } = useAuth(); // Get the logout function from useAuth
  const username = localStorage.getItem("userName");
  const adminType = decrypt(localStorage.getItem("adminType"));

  const currentType =
    adminType === "superadmin"
      ? "Super Admin"
      : adminType === "regularadmin"
      ? "Regular Admin"
      : "Manager Admin";

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const renderAdminIcon = () => {
    if (adminType === "superadmin") {
      return <TbCircleLetterSFilled className="h-10 w-10 text-sky-500" />;
    } else if (adminType === "regularadmin") {
      return <TbCircleLetterRFilled className="h-10 w-10 text-sky-500" />;
    } else {
      return <TbCircleLetterMFilled className="h-10 w-10 text-sky-500" />;
    }
  };

  return (
    <div className="bg-white h-20 flex border-b border-gray-200">
      <div className="flex items-center gap-2 w-full justify-end mr-8"> 
        <Menu as="div" className="relative inline-block text-left border-2 border-gray-400 rounded-lg">
          <div>
            <Menu.Button className=" mr-2 ml-2 inline-flex ">
              <span className="sr-only">Open Admin menu</span>
              <div className="flex items-center mr-5 mt-1 mb-1">
              <div className="h-10 w-10">
                  {renderAdminIcon()}
                </div>

                <div className="ml-2">
                  <span className=" ml-2 mr-2 font-medium text-gray-800">
                    {username.toUpperCase()}
                  </span>
                  <span className="text-xs flex flex-col">{currentType}</span>
                </div>
              </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-1 py-1">
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active && "bg-gray-100",
                        "text-red-600 text-l focus:bg-gray-200 cursor-pointer rounded-sm px-4 py-2"
                      )}
                      onClick={handleLogout}
                    >
                      Logout
                    </div>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}
