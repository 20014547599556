import axios from "axios";
import { jsPDF } from "jspdf";
import React, { useEffect, useState } from "react";
import { TbReportAnalytics } from "react-icons/tb";
import { utils, writeFile } from "xlsx";
import logoImage from "../assets/logo.png";
import apiURL from "../config";
import RecieptLoader from "./loaders/ReciptsLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decrypt } from "../utils/encryptionUtils";

const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();

  // Add leading zero if month or day is less than 10
  if (month < 10) {
    month = `0${month}`;
  }
  if (day < 10) {
    day = `0${day}`;
  }

  return `${year}-${month}-${day}`;
};

const Receipts = () => {
  const [showReportPopup, setShowReportPopup] = useState(false);
  const [reportType, setReportType] = useState("");
  const [repId, setRepId] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState(getCurrentDate());
  const [showRepDetailsPopup, setShowRepDetailsPopup] = useState(false);
  const [repDetails] = useState({});
  const [receipts, setReceipts] = useState([]);
  const [searchInvoiceId, setSearchInvoiceId] = useState("");
  const [repIdSuggestions, setRepIdSuggestions] = useState([]);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [receiptUpdating, setReceiptUpdating] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const adminType = decrypt(localStorage.getItem("adminType"));

  const [editReceipt, setEditReceipt] = useState({
    receipt_id: "",
    invoice_number: "",
    rep_id: "",
    customer_name: "",
    contact_number: "",
    date: "",
    payment_method: "",
    total_payment: "",
    remarks: "",
  });

  useEffect(() => {
    if (adminType === "regularadmin") {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [adminType]);

  useEffect(() => {
    fetchReceipts();
  }, [page]); // Fetch receipts whenever the page state changes

const checkIfUserIsManagerAdmin = () =>{
  return adminType === "manageradmin";
}

  const fetchReceipts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        apiURL + `/api/get-receipts?page=${page}&limit=10`
      );

      // Extract the receipts from the response
      let receipts = response.data;

      // Check if the user is a 'manageradmin' (assuming you have a function or variable for this)
      const isManagerAdmin = checkIfUserIsManagerAdmin(); // Replace this with your actual check

      // Add editable property to each receipt
      receipts = receipts.map((receipt) => {
        const isEdited = receipt.remarks.includes("Changed total payment from");
        return {
          ...receipt,
          editable: isEdited && isManagerAdmin,
        };
      });

      setReceipts(receipts); // Set fetched receipts

      // Extract total pages from the response headers
      const totalPagesFromHeaders =
        parseInt(response.headers["x-total-pages"], 10) || 1;

      setTotalPages(totalPagesFromHeaders);
      setLoading(false);
      // Set total pages
    } catch (error) {
      toast.error("Error fetching receipts. Please try again.");
      console.error("Error fetching receipts:", error);
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  useEffect(() => {
    setRepIdSuggestions(getUniqueRepIds());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receipts]);

  const getUniqueRepIds = () => {
    const uniqueRepIds = new Set(receipts.map((receipt) => receipt.rep_id));
    return [...uniqueRepIds];
  };

  // Add a useEffect to check whether all fields are filled whenever their values change
  useEffect(() => {
    if (reportType === "rep") {
      // Check if all fields for rep report generation are filled
      if (repId && fromDate && toDate) {
        setAllFieldsFilled(true);
      } else {
        setAllFieldsFilled(false);
      }
    } else if (reportType === "time") {
      // Check if all fields for time report generation are filled
      if (fromDate && toDate) {
        setAllFieldsFilled(true);
      } else {
        setAllFieldsFilled(false);
      }
    } else {
      setAllFieldsFilled(false);
    }
  }, [reportType, repId, fromDate, toDate]);

  const handleGenerateReport = () => {
    if (reportType === "rep") {
      generateExcelByRepAndTime();
    } else if (reportType === "time") {
      generateExcelByTime();
    }
    // Close the report generation window after successful report generation
    setShowReportPopup(false);
    // Clear the form data
    setReportType("");
    setRepId("");
    setFromDate("");
    setToDate(getCurrentDate());
  };

  const handleEditReceipt = (receipt) => {
    setEditReceipt({
      ...receipt,
      date: receipt.date.split("T")[0],
    });
    setShowEditPopup(true);
  };

  // Function to handle changes in edited receipt details
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditReceipt((prevReceipt) => ({
      ...prevReceipt,
      [name]: value,
    }));
  };

  // Function to handle saving changes to the receipt details
  const handleSaveChanges = async () => {
    setReceiptUpdating(true);
    try {
      const response = await axios.put(
        `${apiURL}/api/edit-receipt/${editReceipt.receipt_id}`,
        editReceipt
      );
      toast.success("Updated Reciept successfully.");
      setReceiptUpdating(false);
      setShowEditPopup(false);
      fetchReceipts();
    } catch (error) {
      setReceiptUpdating(false);
      toast.error("Error updating receipt");
      console.error("Error updating receipt:", error);
      fetchReceipts();
    }
  };

  const generateExcelByRepAndTime = async () => {
    try {
      // Fetch receipts within the specified repId and time range from the API
      const response = await axios.get(
        `${apiURL}/api/get-receipts-by-rep-and-time?repId=${repId}&fromDate=${fromDate}&toDate=${toDate}`
      );
      const filteredReceipts = response.data;

      // Extract unique rep_ids
      const repIds = [
        ...new Set(filteredReceipts.map((receipt) => receipt.rep_id)),
      ];

      // Fetch rep details by their IDs
      const repResponse = await axios.post(`${apiURL}/api/get-reps-by-ids`, {
        repIds,
      });
      const reps = repResponse.data;

      // Create a map of rep_id to rep name for easy lookup
      const repMap = {};
      reps.forEach((rep) => {
        repMap[rep.rep_id] = rep.name;
      });

      // Sort the filtered receipts
      filteredReceipts.sort((a, b) =>
        a.payment_method.localeCompare(b.payment_method)
      );

      // Calculate total cash amount and total cheque amount
      let totalCashAmount = 0;
      let totalChequeAmount = 0;

      filteredReceipts.forEach((receipt) => {
        if (receipt.payment_method.toLowerCase() === "cash") {
          totalCashAmount += parseFloat(receipt.total_payment);
        } else if (receipt.payment_method.toLowerCase() === "cheque") {
          totalChequeAmount += parseFloat(receipt.total_payment);
        }
      });

      // Prepare data for Excel file
      const data = [];

      data.push({}, {}, {});

      // Prepare data for Excel file
      // Concatenate with the mapped receipts
      data.push(
        ...filteredReceipts.map((receipt) => ({
          "Receipt ID": receipt.receipt_id.toString().padStart(7, "0"),
          Date: receipt.date.split("T")[0],
          "Rep ID": receipt.rep_id,
          "Rep Name": repMap[receipt.rep_id], // Add Rep Name here
          "Customer Name": receipt.customer_name,
          "Invoice Number": receipt.invoice_number,
          "Contact Number": receipt.contact_number,
          "Payment Method": receipt.payment_method,
          "Total Payment": receipt.total_payment.replace(
            /\d(?=(\d{3})+\.)/g,
            "$&,"
          ),
          Remarks: receipt.remarks || "none",
          "Receipt Location": receipt.location,
        }))
      );

      // Convert data to Excel worksheet
      const ws = utils.json_to_sheet(data);

      // Insert heading and date range rows at the top
      utils.sheet_add_aoa(
        ws,
        [
          ["Standard Industries (Pvt) Ltd"],
          [`Receipts from ${fromDate} to ${toDate}`],
          [], // Blank row to separate headers from data
          [
            "Receipt ID",
            "Date",
            "Rep ID",
            "Rep Name",
            "Customer Name",
            "Invoice Number",
            "Contact Number",
            "Payment Method",
            "Total Payment",
            "Remarks",
            "Receipt Location",
          ], // Column headers
        ],
        { origin: "A1" }
      );

      // Merge cells for the heading and date range
      ws["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }, // Merge first row (Standard Industries)
        { s: { r: 1, c: 0 }, e: { r: 1, c: 10 } }, // Merge second row (Receipts from ... to ...)
      ];

      // Center align the text in the merged cells and apply bold font
      const styleCenterBold = {
        alignment: { horizontal: "center" },
        font: { bold: true },
      };
      const styleCenter = { alignment: { horizontal: "center" } };

      // Ensure the cells exist before setting styles
      for (let col = 0; col <= 9; col++) {
        const cellAddress1 = utils.encode_cell({ r: 0, c: col });
        const cellAddress2 = utils.encode_cell({ r: 1, c: col });

        if (!ws[cellAddress1]) ws[cellAddress1] = { t: "s", v: "" };
        if (!ws[cellAddress2]) ws[cellAddress2] = { t: "s", v: "" };

        if (col === 0) {
          ws[cellAddress1].s = styleCenterBold;
          ws[cellAddress2].s = styleCenter;
        }
      }

      // Set column widths
      const columnWidths = [
        { wch: 10 }, // "Receipt ID"
        { wch: 12 }, // "Date"
        { wch: 10 }, // "Rep ID"
        { wch: 20 }, // "Rep Name"
        { wch: 20 }, // "Customer Name"
        { wch: 15 }, // "Invoice Number"
        { wch: 15 }, // "Contact Number"
        { wch: 15 }, // "Payment Method"
        { wch: 15 }, // "Total Payment"
        { wch: 40 }, // "Remarks"
        { wch: 20 }, // "Receipt Location"
      ];

      ws["!cols"] = columnWidths;

      // Find the last row index
      const lastRowIndex = utils.sheet_to_json(ws).length + 3;

      // Add a blank row before total amounts
      utils.sheet_add_aoa(ws, [[]], { origin: `A${lastRowIndex + 1}` });

      // Add row for total cash amount
      utils.sheet_add_aoa(
        ws,
        [
          [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Total Cash Amount(RS)",
            totalCashAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"),
          ],
        ],
        { origin: `A${lastRowIndex + 2}` }
      );

      // Add row for total cheque amount
      utils.sheet_add_aoa(
        ws,
        [
          [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Total Cheque Amount(RS)",
            totalChequeAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"),
          ],
        ],
        { origin: `A${lastRowIndex + 3}` }
      );

      // Create a new workbook and add the worksheet
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Receipts");

      // Generate Excel file
      writeFile(
        wb,
        `Receipts_repID_${repId}#From-${fromDate}_#To-${toDate}.xlsx`
      );
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  const generateExcelByTime = async () => {
    try {
      // Fetch receipts within the specified time range from the API
      const response = await axios.get(
        apiURL +
          `/api/get-receipts-by-time?fromDate=${fromDate}&toDate=${toDate}`
      );
      const filteredReceipts = response.data;

      // Extract unique rep_id values
      const repIds = [
        ...new Set(filteredReceipts.map((receipt) => receipt.rep_id)),
      ];

      // Fetch rep details from the API or database
      const repsResponse = await axios.post(apiURL + "/api/get-reps-by-ids", {
        repIds,
      });
      const reps = repsResponse.data;

      // Create a map of rep_id to rep name
      const repMap = {};
      reps.forEach((rep) => {
        repMap[rep.rep_id] = rep.name;
      });

      // Sort the filtered receipts
      filteredReceipts.sort((a, b) =>
        a.payment_method.localeCompare(b.payment_method)
      );
      filteredReceipts.sort((a, b) => a.rep_id.localeCompare(b.rep_id));

      // Calculate total cash amount and total cheque amount
      let totalCashAmount = 0;
      let totalChequeAmount = 0;

      // Object to store total amounts for each rep
      const repTotalAmounts = {};

      // Calculate total cash amount and total cheque amount for each rep
      filteredReceipts.forEach((receipt) => {
        const repId = receipt.rep_id;
        if (!repTotalAmounts[repId]) {
          repTotalAmounts[repId] = { totalCashAmount: 0, totalChequeAmount: 0 };
        }

        if (receipt.payment_method.toLowerCase() === "cash") {
          repTotalAmounts[repId].totalCashAmount += parseFloat(
            receipt.total_payment
          );
          totalCashAmount += parseFloat(receipt.total_payment);
        } else if (receipt.payment_method.toLowerCase() === "cheque") {
          repTotalAmounts[repId].totalChequeAmount += parseFloat(
            receipt.total_payment
          );
          totalChequeAmount += parseFloat(receipt.total_payment);
        }
      });

      // Prepare data for Excel file
      const data = [];

      let currentRepId = null;

      // Function to add representative total
      function addRepTotal(data, currentRepId) {
        data.push({
          "Receipt ID": "",
          Date: "",
          "Rep ID": "",
          "Rep Name": "",
          "Customer Name": "",
          "Invoice Number": "",
          "Contact Number": "",
          "Payment Method": "",
          "Total Payment": "",
          Remarks: `Total Cash Amount for Rep ID ${currentRepId}(RS):`,
          "Receipt Location": repTotalAmounts[currentRepId].totalCashAmount
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
        });
        data.push({
          "Receipt ID": "",
          Date: "",
          "Rep ID": "",
          "Rep Name": "",
          "Customer Name": "",
          "Invoice Number": "",
          "Contact Number": "",
          "Payment Method": "",
          "Total Payment": "",
          Remarks: `Total Cheque Amount for Rep ID ${currentRepId}(RS):`,
          "Receipt Location": repTotalAmounts[currentRepId].totalChequeAmount
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
        });

        // Add a blank row
        data.push({});
      }

      data.push({}, {}, {}, {});

      filteredReceipts.forEach((receipt, index) => {
        if (index === 0) {
          currentRepId = receipt.rep_id;
        }

        // Check if rep ID changes
        if (currentRepId !== receipt.rep_id) {
          addRepTotal(data, currentRepId);
        }
        // Update currentRepId
        currentRepId = receipt.rep_id;

        data.push({
          "Receipt ID": receipt.receipt_id.toString().padStart(7, "0"),
          Date: receipt.date.split("T")[0],
          "Rep ID": receipt.rep_id,
          "Rep Name": repMap[receipt.rep_id] || "Unknown",
          "Customer Name": receipt.customer_name,
          "Invoice Number": receipt.invoice_number,
          "Contact Number": receipt.contact_number,
          "Payment Method": receipt.payment_method,
          "Total Payment": receipt.total_payment.replace(
            /\d(?=(\d{3})+\.)/g,
            "$&,"
          ),
          Remarks: receipt.remarks || "none",
          "Receipt Location": receipt.location,
        });

        // Check if it's the last receipt
        if (index === filteredReceipts.length - 1) {
          addRepTotal(data, currentRepId);
        }
      });

      // Add row for total cash amount
      data.push({
        "Receipt ID": "",
        Date: "",
        "Rep ID": "",
        "Rep Name": "",
        "Customer Name": "",
        "Invoice Number": "",
        "Contact Number": "",
        "Payment Method": "",
        "Total Payment": "",
        Remarks: `Total Cash Amount(RS):`,
        "Receipt Location": totalCashAmount
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
      });

      // Add row for total cheque amount
      data.push({
        "Receipt ID": "",
        Date: "",
        "Rep ID": "",
        "Rep Name": "",
        "Customer Name": "",
        "Invoice Number": "",
        "Contact Number": "",
        "Payment Method": "",
        "Total Payment": "",
        Remarks: `Total Cheque Amount(RS):`,
        "Receipt Location": totalChequeAmount
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
      });

      // Convert data to Excel worksheet
      const ws = utils.json_to_sheet(data, { skipHeader: true });

      // Insert heading and date range rows at the top
      utils.sheet_add_aoa(
        ws,
        [
          ["Standard Industries (Pvt) Ltd"],
          [`Receipts from ${fromDate} to ${toDate}`],
          [],
          [
            "Receipt ID",
            "Date",
            "Rep ID",
            "Rep Name",
            "Customer Name",
            "Invoice Number",
            "Contact Number",
            "Payment Method",
            "Total Payment",
            "Remarks",
            "Receipt Location",
          ], // Column headers
        ],
        { origin: "A1" }
      );

      // Merge cells for the heading and date range
      ws["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 9 } }, // Merge first row (Standard Industries)
        { s: { r: 1, c: 0 }, e: { r: 1, c: 9 } }, // Merge second row (Receipts from ... to ...)
      ];

      // Center align the text in the merged cells and apply bold font
      const styleCenterBold = {
        alignment: { horizontal: "center" },
        font: { bold: true },
      };
      const styleCenter = { alignment: { horizontal: "center" } };

      // Ensure the cells exist before setting styles
      for (let col = 0; col <= 9; col++) {
        const cellAddress1 = utils.encode_cell({ r: 0, c: col });
        const cellAddress2 = utils.encode_cell({ r: 1, c: col });

        if (!ws[cellAddress1]) ws[cellAddress1] = { t: "s", v: "" };
        if (!ws[cellAddress2]) ws[cellAddress2] = { t: "s", v: "" };

        if (col === 0) {
          ws[cellAddress1].s = styleCenterBold;
          ws[cellAddress2].s = styleCenter;
        }
      }

      // Apply number format to total amounts
      const formatNumber = "#,##0.00";

      // Apply number format to cells containing amounts
      const applyNumberFormat = (cell) => {
        if (!cell) {
          cell = { t: "n", v: 0 };
        }
        cell.z = formatNumber;
      };

      // Apply format to total amounts for each rep
      data.forEach((row, rowIndex) => {
        const remarks = row.Remarks || "";
        if (
          rowIndex >= 3 &&
          (remarks.includes("Total Cash Amount for Rep ID") ||
            remarks.includes("Total Cheque Amount for Rep ID") ||
            remarks === "Total Cash Amount(RS):" ||
            remarks === "Total Cheque Amount(RS):")
        ) {
          const cellAddress = utils.encode_cell({ r: rowIndex + 3, c: 9 }); // Adjusting for the header rows
          applyNumberFormat(ws[cellAddress]);
        }
      });

      // Set column widths
      const columnWidths = [
        { wch: 10 }, // "Receipt ID"
        { wch: 12 }, // "Date"
        { wch: 10 }, // "Rep ID"
        { wch: 20 }, // "Rep Name"
        { wch: 20 }, // "Customer Name"
        { wch: 15 }, // "Invoice Number"
        { wch: 15 }, // "Contact Number"
        { wch: 15 }, // "Payment Method"
        { wch: 15 }, // "Total Payment"
        { wch: 40 }, // "Remarks"
        { wch: 20 }, // "Receipt Location"
      ];

      ws["!cols"] = columnWidths;

      // Create a new workbook and add the worksheet
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Receipts");

      // Generate Excel file
      writeFile(wb, `Receipts_#From-${fromDate}_#To-${toDate}.xlsx`);
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  const handleDownloadReceipt = (receipt) => {
    // Create a new jsPDF instance
    const pdf = new jsPDF();

    // Define the position and dimensions for the image
    const imgWidth = 60;
    const imgHeight = 25;
    const imgX = (pdf.internal.pageSize.getWidth() - imgWidth) / 2; // Center horizontally
    const imgY = 10; // Y-coordinate for the image

    // Define the content for the receipt
    const receiptContent = [
      "Receipt Details: ",
      "-------------------------------------------------------------------------------------------",
      "",
      "Receipt ID:" + receipt.receipt_id.toString().padStart(7, "0"),
      "Invoice Number: " + receipt.invoice_number,
      "Rep ID: " + receipt.rep_id,
      "Customer Name: " + receipt.customer_name,
      "Contact Number: " + receipt.contact_number,
      "Date: " + receipt.date.split("T")[0],
      "Payment Method: " + receipt.payment_method,
      "Total Payment: " +
        receipt.total_payment.replace(/\d(?=(\d{3})+\.)/g, "$&,"),
      "Remarks: " + (receipt.remarks || "none"),
      "Receipt Location: " + receipt.location,
    ];

    // Set font sizes for specific labels and variables
    const labelFontSize = 17;
    const valueFontSize = 12;

    // Add the image to the PDF
    pdf.addImage(logoImage, "PNG", imgX, imgY, imgWidth, imgHeight);

    // Calculate the height of the image to position the text below it
    const imgBottomY = imgY + imgHeight + 5; // Add padding

    // Calculate the maximum width of titles
    let maxWidth = 0;
    receiptContent.forEach((line) => {
      if (line.includes(":")) {
        const splitIndex = line.indexOf(":");
        const title = line.slice(0, splitIndex + 1); // Include the colon
        const titleWidth =
          (pdf.getStringUnitWidth(title) * labelFontSize) /
          pdf.internal.scaleFactor;
        if (titleWidth > maxWidth) {
          maxWidth = titleWidth;
        }
      }
    });

    // Add the receipt content below the image
    receiptContent.forEach((line, index) => {
      const isTitle = line.includes(":"); // Check if line contains a colon
      let title = "";
      let value = "";

      if (isTitle) {
        const splitIndex = line.indexOf(":");
        title = line.slice(0, splitIndex + 1); // Include the colon
        value = line.slice(splitIndex + 1).trim(); // Trim to remove any leading/trailing spaces

        // Calculate X position for value
        const titleX = 10;
        const valueX = titleX + maxWidth + 5; // Add some space between title and value
        pdf.setFontSize(labelFontSize);
        pdf.text(title, titleX, imgBottomY + 10 + index * 10);
        pdf.setFontSize(valueFontSize);
        pdf.text(value, valueX, imgBottomY + 10 + index * 10);
      } else {
        value = line;
      }
    });

    // Save the PDF with a filename
    pdf.save(`receipt_${receipt.invoice_number}.pdf`);
  };

  // Function to filter receipts based on the search query
  const fetchFilteredReceipts = async () => {
    try {
      const response = await axios.get(
        apiURL + `/api/search-receipts?q=${searchInvoiceId}`
      );
      setReceipts(response.data); // Set fetched receipts
    } catch (error) {
      console.error("Error fetching filtered receipts:", error);
    }
  };

  // Call fetchFilteredReceipts whenever the search query changes
  useEffect(() => {
    if (searchInvoiceId !== "") {
      fetchFilteredReceipts();
    } else {
      fetchReceipts();
    }
  }, [searchInvoiceId]);

  return (
    <div className="h-screen flex flex-col relative">
      <ToastContainer position="bottom-right" theme="dark" />
      {showEditPopup && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <button
              onClick={() => setShowEditPopup(false)}
              className="absolute top-2 right-2 p-2"
            >
              <svg
                className="h-6 w-6 text-gray-600"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <h2 className="text-lg font-semibold mb-4">Edit Receipt</h2>
            <form
              className="w-96"
              onSubmit={(e) => {
                e.preventDefault();
                handleSaveChanges();
              }}
            >
              <label className="mb-2">Reciept Number</label>
              <input
                type="text"
                name="receipt_id"
                value={editReceipt.receipt_id.toString().padStart(7, "0")}
                onChange={handleEditChange}
                placeholder="Receipt Number"
                className="border border-gray-300 rounded-md p-2 mb-2 w-full"
                readOnly
              />
              <label className="mb-2">Invoice Number</label>
              <input
                type="text"
                name="invoice_number"
                value={editReceipt.invoice_number}
                onChange={handleEditChange}
                placeholder="Invoice Number"
                className="border border-gray-300 rounded-md p-2 mb-2 w-full"
              />
              <label className="mb-2">Rep ID</label>
              <input
                type="text"
                name="rep_id"
                value={editReceipt.rep_id}
                onChange={handleEditChange}
                placeholder="Rep ID"
                className="border border-gray-300 rounded-md p-2 mb-2 w-full"
                readOnly
              />
              <label className="mb-2">Customer Name</label>
              <input
                type="text"
                name="customer_name"
                value={editReceipt.customer_name}
                onChange={handleEditChange}
                placeholder="Customer Name"
                className="border border-gray-300 rounded-md p-2 mb-2 w-full"
              />
              <label className="mb-2">Contact Number</label>
              <input
                type="text"
                name="contact_number"
                value={editReceipt.contact_number}
                onChange={handleEditChange}
                placeholder="Contact Number"
                className="border border-gray-300 rounded-md p-2 mb-2 w-full"
              />
              <label className="mb-2">Date</label>
              <input
                type="date"
                name="date"
                value={editReceipt.date}
                onChange={handleEditChange}
                placeholder="Date"
                className="border border-gray-300 rounded-md p-2 mb-2 w-full"
                readOnly
              />
              <label className="mb-2">Payment Method</label>
              <select
                name="payment_method"
                value={editReceipt.payment_method}
                onChange={handleEditChange}
                className="border border-gray-300 rounded-md p-2 mb-2 w-full"
              >
                <option value="" disabled>
                  Select Payment Method
                </option>
                <option value="cheque">Cheque</option>
                <option value="cash">Cash</option>
              </select>
              <label className="mb-2">Total</label>
              <input
                type="text"
                name="total_payment"
                value={editReceipt.total_payment}
                onChange={handleEditChange}
                placeholder="Total"
                className="border border-gray-300 rounded-md p-2 mb-2 w-full"
              />
              <div className="flex">
                <button
                  type="submit"
                  disabled={receiptUpdating}
                  className={`bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded-md mr-2 ${
                    receiptUpdating
                      ? "bg-gray-500 hover:bg-gray-600 cursor-not-allowed"
                      : ""
                  }`}
                >
                  Save Changes
                </button>
                <button
                  type="button"
                  onClick={() => setShowEditPopup(false)}
                  className="bg-gray-500 hover:bg-gray-700 text-white px-4 py-2 rounded-md"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showReportPopup && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <button
              onClick={() => setShowReportPopup(false)}
              className="absolute top-2 right-2 p-2"
            >
              <svg
                className="h-6 w-6 text-gray-600"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <h2 className="text-lg font-semibold mb-4">Generate Report</h2>
            <select
              value={reportType}
              onChange={(e) => setReportType(e.target.value)}
              className="border border-gray-300 rounded-md p-2 mb-4"
            >
              <option value="">Select Report Type</option>
              <option value="rep">Repwise</option>
              <option value="time">Timewise</option>
            </select>
            {reportType === "rep" && (
              <div className="flex flex-col gap-4 mb-4">
                <input
                  type="text"
                  value={repId}
                  onChange={(e) => setRepId(e.target.value)}
                  placeholder="Enter Rep ID"
                  className="border border-gray-300 rounded-md p-2"
                  list="repIdSuggestions" // Connect the input to the datalist
                />
                {/* Datalist to display suggestions */}
                <datalist id="repIdSuggestions">
                  {repIdSuggestions.map((suggestion, index) => (
                    <option key={index} value={suggestion} />
                  ))}
                </datalist>
                <input
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  placeholder="From Date"
                  className="border border-gray-300 rounded-md p-2"
                />
                <input
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  placeholder="To Date"
                  className="border border-gray-300 rounded-md p-2"
                  max={getCurrentDate()}
                />
              </div>
            )}

            {reportType === "time" && (
              <div className="flex gap-4 mb-4">
                <input
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  placeholder="From"
                  className="border border-gray-300 rounded-md p-2"
                />
                <input
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  placeholder="To"
                  className="border border-gray-300 rounded-md p-2"
                  max={getCurrentDate()} // Set max attribute to current date
                />
              </div>
            )}
            <div className="flex justify-between items-center">
              <button
                onClick={() => setShowReportPopup(false)}
                className="bg-red-500 hover:bg-red-700 text-white px-4 py-2 rounded-md mr-4"
              >
                Close
              </button>
              <button
                onClick={handleGenerateReport}
                className={`bg-${
                  allFieldsFilled ? "blue" : "gray"
                }-500 hover:bg-${
                  allFieldsFilled ? "blue" : "gray"
                }-700 text-white px-4 py-2 rounded-md ${
                  !allFieldsFilled && "opacity-50 cursor-not-allowed"
                }`}
                disabled={!allFieldsFilled}
              >
                Generate Report
              </button>
            </div>
          </div>
        </div>
      )}
      {showRepDetailsPopup && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-semibold text-gray-800 mb-4">
              Representative Details
            </h2>
            <div className="border-t border-gray-200 pt-4">
              <p className="text-gray-700 mb-2">
                <span className="font-semibold">Rep ID:</span>{" "}
                {repDetails.repId}
              </p>
              <p className="text-gray-700 mb-2">
                <span className="font-semibold">Name:</span> {repDetails.name}
              </p>
              <p className="text-gray-700 mb-2">
                <span className="font-semibold">Mobile:</span>{" "}
                {repDetails.mobile}
              </p>
              <p className="text-gray-700 mb-4">
                <span className="font-semibold">NIC:</span> {repDetails.nic}
              </p>
            </div>
            {/* Red close button */}
            <button
              onClick={() => setShowRepDetailsPopup(false)}
              className="bg-red-500 text-white px-4 py-2 rounded-md mt-4 focus:outline-none hover:bg-red-600 transition-colors duration-300"
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="flex flex-row space-x-5">
        <button
          onClick={() => setShowReportPopup(true)}
          className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded-md top-4 right-4 flex items-center"
        >
          <TbReportAnalytics className="mr-2" />
          Generate Report
        </button>
        <input
          type="text"
          placeholder="Search by Invoice ID..."
          className="border border-gray-300 rounded-md p-2"
          value={searchInvoiceId}
          onChange={(e) => setSearchInvoiceId(e.target.value)}
        />
      </div>

      <div className=" overflow-auto h-3/4 mt-3">
        <table className="table-auto w-full border-collapse">
          <thead>
            <tr>
              <th
                className={`px-4 py-2 bg-gray-800 text-white text-center ${
                  !showReportPopup && !showEditPopup && "sticky top-0"
                }`}
              >
                Receipt No
              </th>
              <th
                className={`px-4 py-2 bg-gray-800 text-white text-center ${
                  !showReportPopup && !showEditPopup && "sticky top-0"
                }`}
              >
                Invoice No
              </th>
              <th
                className={`px-4 py-2 bg-gray-800 text-white text-center ${
                  !showReportPopup && !showEditPopup && "sticky top-0"
                }`}
              >
                Rep ID
              </th>
              <th
                className={`px-4 py-2 bg-gray-800 text-white text-center ${
                  !showReportPopup && !showEditPopup && "sticky top-0"
                }`}
              >
                Customer Name
              </th>
              <th
                className={`px-4 py-2 bg-gray-800 text-white text-center ${
                  !showReportPopup && !showEditPopup && "sticky top-0"
                }`}
              >
                Contact Number
              </th>
              <th
                className={`px-4 py-2 bg-gray-800 text-white text-center ${
                  !showReportPopup && !showEditPopup && "sticky top-0"
                }`}
              >
                Date
              </th>
              <th
                className={`px-4 py-2 bg-gray-800 text-white text-center ${
                  !showReportPopup && !showEditPopup && "sticky top-0"
                }`}
              >
                Payment Method
              </th>
              <th
                className={`px-4 py-2 bg-gray-800 text-white text-center ${
                  !showReportPopup && !showEditPopup && "sticky top-0"
                }`}
              >
                Total
              </th>
              <th
                className={`px-4 py-2 bg-gray-800 text-white text-center ${
                  !showReportPopup && !showEditPopup && "sticky top-0"
                }`}
              >
                Remarks
              </th>
              <th
                className={`px-4 py-2 bg-gray-800 text-white text-center ${
                  !showReportPopup && !showEditPopup && "sticky top-0"
                }`}
              >
                Action
              </th>
            </tr>
          </thead>
          {loading ? (
            <RecieptLoader />
          ) : (
            <tbody>
              {receipts.map((receipt, index) => (
                <tr key={index} className="hover:bg-gray-200">
                  <td className="border px-4 py-2 text-center">
                    {receipt.receipt_id.toString().padStart(7, "0")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {receipt.invoice_number}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {receipt.rep_id}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {receipt.customer_name}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {receipt.contact_number}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {receipt.date.split("T")[0]}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {receipt.payment_method}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {receipt.total_payment.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {receipt.remarks !== "" ? receipt.remarks : "none"}
                  </td>
                  <td className="border px-4 py-2 text-center flex-col">
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white px-3 py-1 rounded-md mb-1 mx-1"
                      onClick={() => handleDownloadReceipt(receipt)}
                    >
                      Download
                    </button>
                    {!btnDisabled && (
                      <button
                        className={`text-white px-8 py-1 rounded-md ${receipt.editable ? 'bg-gray-500 cursor-not-allowed' : 'bg-green-500 hover:bg-green-700'}`}
                        disabled={receipt.editable}
                        onClick={() => handleEditReceipt(receipt)}
                      >
                        Edit
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={handlePrevPage}
          disabled={page === 1 || loading === true}
          className={`mr-2 bg-gray-200 px-3 py-1 rounded-md ${
            page === 1 ? "cursor-not-allowed" : ""
          }`}
        >
          Prev
        </button>
        <span>
          Page {page} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={page === totalPages || loading === true}
          className={`ml-2 bg-gray-200 px-3 py-1 rounded-md ${
            page === totalPages || loading === true ? "cursor-not-allowed" : ""
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Receipts;
