import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa6";
import AddAdmin from "./AddAdmin";
import axios from "axios";
import apiURL from "../config";
import EditAdmin from "./EditAdmin";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminSkeleton from "./loaders/AdminSkeleton";

const Admin = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [loading, setLoading] = useState(false);

  // Function to fetch admins from the database
  const fetchAdmins = async () => {
    setLoading(true);
    try {
      const response = await axios.get(apiURL + "/api/get-admins");
      if (response.status === 200) {
        setAdmins(response.data);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error fetching admins. Please try again.");
      console.error("Error fetching admins:", error);
      // Handle error
      setLoading(false);
    }
  };

  // Fetch admin data when component mounts
  useEffect(() => {
    fetchAdmins();
  }, []); // Empty dependency array ensures useEffect runs only once

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddAdmin = (admin) => {
    closeModal();
    fetchAdmins();
  };

  const openEditModal = (admin) => {
    setSelectedAdmin(admin);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const deleteAdmin = async (adminId) => {
    try {
      const response = await axios.delete(
        apiURL + `/api/delete-admin/${adminId}`
      );
      if (response.status === 200) {
        toast.success(`Admin deleted successfully.`);
        // Filter out the deleted admin from the admins list
        fetchAdmins();
      }
    } catch (error) {
      toast.error(`Error deleting Admins. Please try again.`);
      console.error("Error deleting admin:", error);
      // Handle error
    }
  };

  const handleDeleteConfirmation = (adminId) => {
    if (window.confirm("Are you sure you want to delete this admin?")) {
      deleteAdmin(adminId);
    }
  };

  const handleUpdateAdmin = () => {
    fetchAdmins();

    closeEditModal();
  };

  return (
    <div className="h-screen">
      <ToastContainer position="bottom-right" theme="dark" />
      <div className="mt-4">
        <div className="flex gap-4 w-full h-full">
          <div>
            <button
              onClick={openModal}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 flex items-center"
            >
              <FaPlus className="mr-2" />
              Add Admins
            </button>
          </div>
          {isEditModalOpen && (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
              <div className="bg-white p-6 rounded-lg shadow-lg relative">
                <EditAdmin
                  adminData={selectedAdmin}
                  onClose={closeEditModal}
                  onUpdateAdmin={handleUpdateAdmin}
                  existingAdmins={admins}
                />
              </div>
            </div>
          )}

          {isModalOpen && (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
              <div className="bg-white p-6 rounded-lg shadow-lg relative">
                <button
                  onClick={closeModal}
                  className="absolute top-0 right-0 p-2"
                >
                  <svg
                    className="h-6 w-6 text-gray-600"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>

                <AddAdmin
                  onClose={closeModal}
                  onAddAdmin={handleAddAdmin}
                  existingAdmins={admins}
                />
              </div>
            </div>
          )}
        </div>
        <div className="overflow-auto h-4/6 mt-3">
          <table className="table-auto w-full border-collapse">
            <thead>
              <tr>
                <th className="px-4 py-2 bg-gray-800 text-white text-center">
                  AdminID
                </th>
                <th className="px-4 py-2 bg-gray-800 text-white text-center">
                  Name
                </th>
                <th className="px-4 py-2 bg-gray-800 text-white text-center">
                  Mobile Number
                </th>

                <th className="px-4 py-2 bg-gray-800 text-white text-center">
                  Type
                </th>
                <th className="px-4 py-2 bg-gray-800 text-white text-center">
                  Action
                </th>
              </tr>
            </thead>
            {loading ? (
              <AdminSkeleton />
            ) : (
              <tbody>
                {admins.map((admin, index) => (
                  <tr key={index} className="hover:bg-gray-200">
                    <td className="border px-4 py-2 text-center">
                      {admin.admin_id}
                    </td>
                    <td className="border px-4 py-2 text-center">
                      {admin.name}
                    </td>
                    <td className="border px-4 py-2 text-center">
                      {admin.mobile_number !== null ? admin.mobile_number : "-"}
                    </td>
                    <td className="border px-4 py-2 text-center">
                      {admin.type === "superadmin"
                        ? "Super Admin"
                        : admin.type === "regularadmin"
                        ? "Regular Admin"
                        : "Manager Admin"}
                    </td>

                    <td className="border px-4 py-2 text-center">
                      <button
                        className="bg-purple-600 hover:bg-purple-700 text-white px-2 py-1 rounded-md mr-2"
                        onClick={() => openEditModal(admin)}
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDeleteConfirmation(admin.admin_id)}
                        className="bg-red-600 hover:bg-red-700 text-white px-2 py-1 rounded-md"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Admin;
