import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import apiURL from "../config";

const MonthlyReceiptsChart = () => {
  const [monthlyReceipts, setMonthlyReceipts] = useState([]);

  useEffect(() => {
    // Fetch monthly receipts data from the API
    fetch(apiURL + "/api/monthly-receipts")
      .then((response) => response.json())
      .then((data) => {
        // Preprocess data to include all months
        const allMonthsData = [];
        for (let i = 1; i <= 12; i++) {
          const monthData = data.find((item) => item.month === i);
          if (monthData) {
            allMonthsData.push(monthData);
          } else {
            allMonthsData.push({ month: i, totalReceipts: 0 });
          }
        }
        setMonthlyReceipts(allMonthsData);
      })
      .catch((error) =>
        console.error("Error fetching monthly receipts data:", error)
      );
  }, []);

  return (
    <div className="w-full mx-auto bg-white p-4 rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-4 text-center">
        Monthly Receipts
      </h2>
      <BarChart
        width={1350}
        height={400}
        data={monthlyReceipts}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="month"
          tickFormatter={(month) => {
            const monthNames = [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ];
            return monthNames[month - 1];
          }}
        />
        <YAxis />
        <Tooltip
          formatter={(value, name, props) => [value, "Total Receipts"]}
          labelFormatter={(value) => {
            const monthNames = [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ];
            return monthNames[value - 1];
          }}
        />
        <Legend />
        <Bar dataKey="totalReceipts" fill="#8884d8" />
      </BarChart>
    </div>
  );
};

export default MonthlyReceiptsChart;
