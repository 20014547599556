import React from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Layout from './components/shared/Layout';
import Reps from './components/Reps';
import LoginPage from './components/LoginPage';
import Dashboard from './components/Dashboard';
import Recipts from './components/Recipts';
import Admin from './components/Admin';
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/*" element={
              <PrivateRoute element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route path="reps" element={<Reps />} />
                <Route path="recipts" element={<Recipts />} />
                <Route path="admins" element={<Admin />} />
              </PrivateRoute>
            }
          />
          <Route path="login" element={<LoginPage />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
