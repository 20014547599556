import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";

export default function Layout() {
  return (
    <div className="flex flex-row bg-neutral-100 h-screen w-screen overflow-auto md:overflow-auto xl:overflow-hidden">
      {/* import Sidebar in here */}
      <Sidebar />
      <div className="flex-1">
        {/* import header in here */}
        <Header />
        <div className="p-4">{<Outlet />}</div>
      </div>
    </div>
  );
}
