import React from "react";
import { DASHBOARD_SIDEBAR_LINKS } from "../../lib/consts/navigation";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import Logo from "../../assets/logo.png";
import { decrypt } from "../../utils/encryptionUtils";

const linkClssses =
  "flex item-center gap-2 font-medium px-3 py-2 hover:bg-primary hover:text-white hover:no-underline active:primary rounded-md text-base";

export default function Sidebar() {
  const adminType = decrypt(localStorage.getItem("adminType"));

  return (
    <div className="bg-white w-60 p-3 flex flex-col text-white">
      {/* name and logo section */}
      <div className="flex items-center ">
        <img src={Logo} alt="Description" />
      </div>

      {/* side bar navigation links */}
      <div className="flex-1 py-8 flex flex-col gap-0.5">
        {DASHBOARD_SIDEBAR_LINKS.map((item) => {
          // Render "Admins" link only if adminType is "superadmin"
          if (item.key === 'admins' && adminType !== 'superadmin') {
            return null; // Skip rendering "Admins" link
          }
          return <SidebarLink key={item.key} item={item} />;
        })}
      </div>
    </div>
  );
}

// this function for button hover color change
function SidebarLink({ item }) {
  const { pathname } = useLocation();
  return (
    <Link
      to={item.path}
      className={classNames(
        pathname === item.path ? "bg-primary  text-white" : "text-neutral-500",
        linkClssses
      )}
    >
      <span className="text-xl">{item.icon}</span>
      {item.label}
    </Link>
  );
}
