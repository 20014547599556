import React, { createContext, useState, useContext, useEffect } from 'react';
import apiURL from '../config';
import { toast } from 'react-toastify';
import { encrypt } from '../utils/encryptionUtils';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Initialize loading state to true

  useEffect(() => {
    // Check if user session exists on mount
    const userSession = localStorage.getItem('userSession');
    if (userSession) {
      setIsAuthenticated(true);
    }
    setLoading(false); // Set loading to false after checking user session
  }, []);

  const login = async (username, password) => {
    setLoading(true); // Set loading to true before login request
    try {
      const response = await fetch(apiURL + '/api/admin-login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      if (response.ok) {
        const data = await response.json();
        const { token, adminType } = data;
        localStorage.setItem('userSession', token);
        localStorage.setItem('adminType', encrypt(adminType));
        localStorage.setItem('userName', username);
        setIsAuthenticated(true);
      } else {
        toast.error("Login failed. Please check your credentials and try again.");
        // console.error('Failed to authenticate');
      }
    } catch (error) {
      console.error('Error during authentication:', error);
    } finally {
      setLoading(false); // Set loading to false after login request completes
    }
  };

  const logout = () => {
    localStorage.removeItem('userSession');
    localStorage.removeItem('adminType');
    localStorage.removeItem('userName');
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
