import React, { useState, useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import apiURL from "../config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditAdmin = ({ adminData, onClose, onUpdateAdmin, existingAdmins }) => {
  const [name, setName] = useState(adminData.name);
  const [number, setNumber] = useState(adminData.mobile_number);
  const [type, setType] = useState(adminData.type);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [usernameExist, setUsernameExist] = useState(false);

  useEffect(() => {
    setName(adminData.name);
    setNumber(adminData.mobile_number);
    setType(adminData.type);
  }, [adminData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (changePassword && password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    const updatedAdmin = {
      name,
      mobile_number: type === "superadmin" ? number : undefined,
      type,
      password: changePassword ? password : undefined,
    };

    try {
      const response = await axios.put(
        `${apiURL}/api/update-admin/${adminData.admin_id}`,
        updatedAdmin
      );

      if (response.status === 200) {
        setPassword("");
        setConfirmPassword("");
        onUpdateAdmin(updatedAdmin);
        onClose(); // Close the modal after successful update
      }
      toast.success("Admin updated successfully");
    } catch (error) {
      toast.error("Error updating admin");
      console.error("Error updating admin:", error);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleCheckboxChange = () => {
    setChangePassword(!changePassword);
  };

  useEffect(() => {
    const lowercaseName = name.toLowerCase().replace(/[^a-z0-9]/g, "");
    if (name !== lowercaseName) {
      setName(lowercaseName);
    }
    if (
      existingAdmins.some(
        (admin) => admin.name === name && name !== adminData.name
      )
    ) {
      setUsernameExist(true);
      // alert("Username already exists");
    } else {
      setUsernameExist(false);
    }
  }, [name]);

  return (
    <div className="p-4 bg-white rounded-lg shadow-md w-96">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Edit Admin</h2>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4 mt-8">
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Username
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className={`pl-2 mt-3 pt-2 pb-2 block w-full border-gray-300 rounded-md shadow-md focus:outline-none border-b ${
              usernameExist ? "border-red-500 border" : ""
            }`}
          />
          <p
            className={`text-red-600 text-xs ${usernameExist ? "" : "hidden"}`}
          >
            *Username exists
          </p>
        </div>
        {type === "superadmin" && (
          <div>
            <label
              htmlFor="number"
              className="block text-sm font-medium text-gray-700"
            >
              Mobile Number
            </label>
            <input
              type="number"
              id="number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              required
              style={{ WebkitAppearance: "none", MozAppearance: "textfield" }}
              className="pl-2 mt-3 pt-2 pb-2 block w-full border-gray-300 rounded-md shadow-md focus:outline-none border-b"
            />
          </div>
        )}
        <div>
          <label
            htmlFor="type"
            className="block text-sm font-medium text-gray-700"
          >
            Admin Type
          </label>
          <select
            id="type"
            value={type}
            onChange={(e) => setType(e.target.value)}
            required
            disabled // Adding disabled attribute to make it read-only
            className="pl-2 mt-3 pt-2 pb-2 block w-full border-gray-300 rounded-md shadow-md focus:outline-none border-b"
          >
            <option value="superadmin">Super Admin</option>
            <option value="regularadmin">Regular Admin</option>
          </select>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="changePassword"
            checked={changePassword}
            onChange={handleCheckboxChange}
            className="mr-2"
          />
          <label
            htmlFor="changePassword"
            className="text-sm font-medium text-gray-700"
          >
            Change Password
          </label>
        </div>
        {changePassword && (
          <>
            <div className="relative">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                New Password
              </label>
              <input
                type={passwordVisible ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="pl-2 mt-3 pt-2 pb-2 block w-full border-gray-300 rounded-md shadow-md focus:outline-none border-b"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
              >
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            <div className="relative">
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm New Password
              </label>
              <input
                type={passwordVisible ? "text" : "password"}
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="pl-2 mt-3 pt-2 pb-2 block w-full border-gray-300 rounded-md shadow-md focus:outline-none border-b"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
              >
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </>
        )}
        <button
          type="submit"
          className={`mt-4 text-white px-4 py-2 rounded-md transition duration-300 ${
            usernameExist
              ? "bg-gray-500 cursor-not-allowed"
              : "bg-indigo-500 hover:bg-indigo-600"
          }`}
          disabled={usernameExist}
        >
          Update Admin
        </button>
        <button
          type="button"
          onClick={onClose}
          className="ml-3 mt-2 bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition duration-300"
        >
          Cancel
        </button>
      </form>
    </div>
  );
};

export default EditAdmin;
