import React, { useState, useEffect } from "react";
import { FaMoneyBillAlt } from "react-icons/fa";
import { MdCalendarMonth } from "react-icons/md";
import { FaPeopleGroup } from "react-icons/fa6";
import apiURL from "../config";

export default function DashboardStatsGrid() {
  const [totalMonthlyReceipts, setTotalMonthlyReceipts] = useState(0);
  const [totalMonthlyAmount, setTotalMonthlyAmount] = useState(0);
  const [totalRepresentatives, setTotalRepresentatives] = useState(0);

  useEffect(() => {
    // Fetch total monthly receipts
    fetch(apiURL + '/api/total-monthly-receipts')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setTotalMonthlyReceipts(data.totalMonthlyReceipts);
      })
      .catch((error) => {
        console.error('Error fetching total monthly receipts:', error);
      });

    // Fetch total monthly amount
    fetch(apiURL + "/api/total-monthly-amount")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTotalMonthlyAmount(data.totalMonthlyAmount);
      })
      .catch((error) => {
        console.error("Error fetching total monthly amount:", error);
      });

    // Fetch total representatives
    fetch(apiURL + "/api/total-representatives")
      .then((response) => response.json())
      .then((data) => setTotalRepresentatives(data.totalRepresentatives))
      .catch((error) =>
        console.error("Error fetching total representatives:", error)
      );
  }, []);

  return (
    <div className="flex gap-10 w-full">
      <BoxWrapper>
        <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
          <FaMoneyBillAlt className="text-2xl text-white" />
        </div>
        <div className="pl-4">
          <span className="text-sm text-gray-500 font-light">
            Total Monthly Receipts
          </span>
          <div className="flex items-center">
            <strong className="text-xl text-gray-700 font-semibold">
              {Intl.NumberFormat().format(totalMonthlyReceipts)}
            </strong>
          </div>
        </div>
      </BoxWrapper>
      <BoxWrapper>
        <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
          <MdCalendarMonth className="text-2xl text-white" />
        </div>
        <div className="pl-4">
          <span className="text-sm text-gray-500 font-light">
            Monthly Transactions(Rs)
          </span>
          <div className="flex items-center">
            <strong className="text-xl text-gray-700 font-semibold">
              {Intl.NumberFormat().format(totalMonthlyAmount)}
            </strong>
          </div>
        </div>
      </BoxWrapper>
      <BoxWrapper>
        <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
          <FaPeopleGroup className="text-2xl text-white" />
        </div>
        <div className="pl-4">
          <span className="text-sm text-gray-500 font-light">Total Reps</span>
          <div className="flex items-center">
            <strong className="text-xl text-gray-700 font-semibold">
              {Intl.NumberFormat().format(totalRepresentatives)}
            </strong>
          </div>
        </div>
      </BoxWrapper>
    </div>
  );
}

function BoxWrapper({ children }) {
  return (
    <div className="bg-white rounded-md p-4 flex-1 border border-gray-200 flex items-center">
      {children}
    </div>
  );
}
