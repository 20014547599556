import React, { useState } from "react";

const EditRepForm = ({ onClose, onEditRep, repData, onResetPassword }) => {
  const [repNo, setRepNo] = useState(repData.rep_id);
  const [name, setName] = useState(repData.name);
  const [number, setNumber] = useState(repData.mobile_number);
  const [nicNumber, setNicNumber] = useState(repData.NIC_Number);

  const handleSubmit = (e) => {
    e.preventDefault();
    onEditRep({
      rep_id: repNo,
      name,
      mobile_number: number,
      NIC_Number: nicNumber,
    });
    onClose();
  };

  const handleResetPassword = () => {
    const isConfirmed = window.confirm(
      `Are you sure you want to reset the password for the representative with ID: ${repNo}?`
    );

    if (isConfirmed) {
      onResetPassword(repData.rep_id); // Call the function provided from RepsPage
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md w-96">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Edit Representative</h2>
        <div className="flex items-center">
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-800"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4 mb-4">
        <div>
          <label
            htmlFor="repNo"
            className="block text-md font-medium text-gray-700"
          >
            RepNo
          </label>
          <input
            type="text"
            id="repNo"
            value={repNo}
            onChange={(e) => setRepNo(e.target.value)}
            readOnly
            required
            className="pl-2 mt-3 pt-2 pb-2 block w-full  border-gray-300 rounded-md shadow-md  focus:outline-none border-b"
          />
        </div>
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="pl-2 mt-3 pt-2 pb-2 block w-full  border-gray-300 rounded-md shadow-md  focus:outline-none border-b"
          />
        </div>
        <div>
          <label
            htmlFor="number"
            className="block text-sm font-medium text-gray-700"
          >
            Number
          </label>
          <input
            type="text"
            id="number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            required
            className="pl-2 mt-3 pt-2 pb-2 block w-full  border-gray-300 rounded-md shadow-md  focus:outline-none border-b0"
          />
        </div>
        <div>
          <label
            htmlFor="nicNumber"
            className="block text-sm font-medium text-gray-700"
          >
            NIC Number
          </label>
          <input
            type="text"
            id="nicNumber"
            value={nicNumber}
            onChange={(e) => setNicNumber(e.target.value)}
            required
            className="pl-2 mt-3 mb-8 pt-2 pb-2 block w-full  border-gray-300 rounded-md shadow-md  focus:outline-none border-b"
          />
        </div>
        <div className="space-x-4">
          <button
            type="submit"
            className="bg-indigo-500 text-white ml-8 px-4 py-2 rounded-md hover:bg-indigo-600 transition duration-300"
          >
            Save Changes
          </button>
          <button
            type="button"
            onClick={handleResetPassword}
            className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 mr-4"
          >
            Reset Password
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditRepForm;
