import CryptoJS from "crypto-js";

const secretKey = "2f8a9f9a4dcbf2b12d84c3a5a9f52d1b8c12f77a3c847a8f2d3b1d4a6f9e2b1c"; // You should store this securely

// Encrypt a string
export const encrypt = (text) => {
  return CryptoJS.AES.encrypt(text, secretKey).toString();
};

// Decrypt a string
export const decrypt = (cipherText) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
