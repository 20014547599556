import React from "react";
import DashboardStatsGrid from "./DashboardStatsGrid";
import LatestTranscations from "./LatestTranscations";
import SimpleBarChart from "./SimpleBarChart";

export default function Dashboard() {
  return (
    <div className="flex flex-col gap-4 h-screen overflow-y-auto">
      <DashboardStatsGrid />
      <div>
        <SimpleBarChart />
        <div className="mt-4">
          <LatestTranscations />
        </div>
      </div>
    </div>
  );
}
