import React from 'react';

const AdminSkeleton = () => {
    const rows = Array(6).fill(null); // Adjust the number of rows based on your needs

    return (
      <tbody>
        {rows.map((_, index) => (
          <tr key={index} className="animate-pulse">
            <td className="border px-4 py-2 text-center">
              <div className="h-4 bg-gray-300 rounded"></div>
            </td>
            <td className="border px-4 py-2 text-center">
              <div className="h-4 bg-gray-300 rounded"></div>
            </td>
            <td className="border px-4 py-2 text-center">
              <div className="h-4 bg-gray-300 rounded"></div>
            </td>
            <td className="border px-4 py-2 text-center">
              <div className="h-4 bg-gray-300 rounded"></div>
            </td>
            <td className="border px-4 py-2 text-center">
              <div className="h-8 bg-gray-300 rounded w-24 mx-auto"></div>
            </td>
          </tr>
        ))}
      </tbody>
    );
};

export default AdminSkeleton;