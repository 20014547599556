import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import apiURL from "../config";
import { toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const AddAdmin = ({ onClose, onAddAdmin, existingAdmins }) => {
  const [AdminID, setAdminId] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [type, setType] = useState("");
  const [otp, setOtp] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showOtpField, setShowOtpField] = useState(false); // State to toggle OTP field visibility
  const [generatedOTP, setGeneratedOTP] = useState("");
  const [usernameExist, setUsernameExist] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    if (type === "superadmin" && !otp) {
      // If super admin, but OTP is not entered, prompt for OTP
      alert("Please enter OTP");
      return;
    }

    if (type === "superadmin" && otp !== generatedOTP) {
      // If super admin, but OTP is not entered, prompt for OTP
      alert("Invalid OTP");
      return;
    }

    

    const newAdmin = { AdminID, name, number, password, type };

    try {
      const response = await axios.post(apiURL + "/api/add-admin", newAdmin);
      if (response.status === 200) {
        toast.success("Admin added successfully");
        console.log("Admin added successfully");
        // Clear the form fields
        setAdminId("");
        setName("");
        setNumber("");
        setPassword("");
        setConfirmPassword("");
        setType("");
        setOtp("");
        setShowOtpField(false); // Hide OTP field after successful submission
        // Call the onAddAdmin function if needed
        onAddAdmin(newAdmin);
      }
    } catch (error) {
      toast.error("Error adding admin. Please try again.");
      console.error("Error adding admin:", error);
      alert("Failed to add admin");
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleRequestOtp = async () => {
    try {
      const response = await axios.post(apiURL + "/api/request-otp", {
        contact_number: number,
      });
      if (response.status === 200) {
        // console.log("OTP requested successfully");
        setShowOtpField(true); // Show OTP field after requesting OTP
        const { otp } = response.data;
        setGeneratedOTP(otp);
      }
    } catch (error) {
      console.error("Error requesting OTP:", error);
      alert("Failed to request OTP");
    }
  };

  useEffect(() => {
    const lowercaseName = name.toLowerCase().replace(/[^a-z0-9]/g, '');
    if (name !== lowercaseName) {
      setName(lowercaseName);
    }
    if (existingAdmins.some(admin => admin.name === name)) {
      setUsernameExist(true);
      // alert("Username already exists");
    }else{
      setUsernameExist(false);
    }
  }, [name]);

  return (
    <div className="p-4 bg-white rounded-lg shadow-md w-96">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Add Admins</h2>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4 mt-8">
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Username
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className={`pl-2 mt-3 pt-2 pb-2 block w-full border-gray-300 rounded-md shadow-md focus:outline-none border-b ${usernameExist ? 'border-red-500 border' : ''}`}
          />
          <p className={`text-red-600 text-xs ${usernameExist ? '': 'hidden'}`}>*Username exists</p>
        </div>
        <div>
          <label
            htmlFor="type"
            className="block text-sm font-medium text-gray-700"
          >
            Admin Type
          </label>
          <select
            id="type"
            value={type}
            onChange={(e) => setType(e.target.value)}
            required
            className="pl-2 mt-3 pt-2 pb-2 block w-full border-gray-300 rounded-md shadow-md focus:outline-none border-b"
          >
            <option value="" disabled>
              Select Type
            </option>
            <option value="superadmin">Super Admin</option>
            <option value="manageradmin">Manager Admin</option>
            <option value="regularadmin">Regular Admin</option>
          </select>
        </div>
        {type === "superadmin" && (
          <div>
            <label
              htmlFor="number"
              className="block text-sm font-medium text-gray-700"
            >
              Number
            </label>
            <input
              type="number"
              id="number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              required
              className="pl-2 mt-3 pt-2 pb-2 block w-full border-gray-300 rounded-md shadow-md focus:outline-none border-b"
            />
          </div>
        )}
        <div className="relative">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Password
          </label>
          <input
            type={passwordVisible ? "text" : "password"}
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="pl-2 mt-3 pt-2 pb-2 block w-full border-gray-300 rounded-md shadow-md focus:outline-none border-b"
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
          >
            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
        <div className="relative">
          <label
            htmlFor="confirmPassword"
            className="block text-sm font-medium text-gray-700"
          >
            Confirm Password
          </label>
          <input
            type={passwordVisible ? "text" : "password"}
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className="pl-2 mt-3 pt-2 pb-2 block w-full border-gray-300 rounded-md shadow-md focus:outline-none border-b"
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
          >
            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>

        {showOtpField && (
          <div>
            <label
              htmlFor="otp"
              className="block text-sm font-medium text-gray-700"
            >
              OTP
            </label>
            <input
              type="text"
              id="otp"
              value={otp}
              onChange={handleOtpChange}
              required
              className="pl-2 mt-3 pt-2 pb-2 block w-full border-gray-300 rounded-md shadow-md focus:outline-none border-b"
            />
          </div>
        )}

        <div className="flex flex-row-reverse ">
          {/* Request OTP button for superadmin */}
        {type === "superadmin" && !showOtpField && (
          <button
            type="button"
            onClick={handleRequestOtp}
            className=" bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 transition duration-300 mr-14"
          >
            Request OTP
          </button>
        )}

        {/* Add Admin button */}
        <button
          type="submit"
          className={`mr-4 text-white px-4 py-2 rounded-md transition duration-300 ${usernameExist ? 'bg-gray-500 cursor-not-allowed' : 'bg-indigo-500 hover:bg-indigo-600'}`}
          disabled={usernameExist}
        >
          Add Admin
        </button>
        </div>
      </form>
    </div>
  );
};

export default AddAdmin;
