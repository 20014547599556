import React, { useState, useEffect } from "react";
import AddRepForm from "./AddRepForm";
import EditRepForm from "./EditRepForm";
import { FaPlus } from "react-icons/fa6";
import axios from "axios";
import apiURL from "../config";
import SkeletonTable from "./loaders/SkeletonTable";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decrypt } from "../utils/encryptionUtils";

const RepsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [reps, setReps] = useState([]);
  const [selectedRep, setSelectedRep] = useState(null);
  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const adminType = decrypt(localStorage.getItem("adminType"));

  useEffect(() => {
    if (adminType === "regularadmin") {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [adminType]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(apiURL + "/api/get-reps")
      .then((response) => {
        setReps(response.data);
        setLoading(false); // Update the state with fetched representatives
      })
      .catch((error) => {
        toast.error("Error fetching representatives. Please try again.");
        console.error("Error fetching representatives:", error);
        setLoading(false);
      });

    // Fetch representatives from the API when the component mounts
  }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openEditModal = (rep) => {
    setSelectedRep(rep);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedRep(null);
  };

  const handleAddRep = (repData) => {
    axios
      .post(apiURL + "/api/add-rep", repData)
      .then((response) => {
        toast.success("Representative added successfully.");
        axios
          .get(apiURL + "/api/get-reps")
          .then((response) => {
            setReps(response.data); // Update the state with fetched representatives
          })
          .catch((error) => {
            toast.error("Error fetching representatives after adding.");
            console.error("Error fetching representatives:", error);
          });
        closeModal();
      })
      .catch((error) => {
        toast.error("Error adding representative. Please try again.");
        console.error("Error adding rep:", error);
        // Handle error scenarios as needed
      });
  };

  const handleEditRep = (editedRepData) => {
    axios
      .put(apiURL + "/api/update-rep/" + editedRepData.rep_id, editedRepData)
      .then((response) => {
        toast.success(
          `Representative(${editedRepData.rep_id}) updated successfully`
        );
        console.log("Rep updated successfully:", response.data);
        // Update the reps state with the modified array
        setReps(
          reps.map((rep) =>
            rep.rep_id === editedRepData.rep_id ? editedRepData : rep
          )
        );
        // Close the edit modal
        closeEditModal();
      })
      .catch((error) => {
        toast.error(`Error updating Representative(${editedRepData.rep_id})`);
        console.error("Error updating rep:", error);
        // Handle error scenarios as needed
      });
  };

  const handleDelete = (id) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to delete this representative with ID: ${id}?`
    );

    if (isConfirmed) {
      axios
        .delete(`${apiURL}/api/delete-rep/${id}`)
        .then((response) => {
          // Update the reps state by filtering out the deleted representative
          toast.success(`Representative(${id}) deleted successfully.`);
          setReps(reps.filter((rep) => rep.rep_id !== id));
        })
        .catch((error) => {
          toast.error(
            `Error deleting representative(${id}). Please try again.`
          );
          console.error("Error deleting rep:", error);
          // Handle error scenarios as needed
        });
    }
  };

  const handleResetPassword = (repId) => {
    axios
      .post(`${apiURL}/api/reset-password`, { username: repId })
      .then((response) => {
        toast.success("Password reset successfully");
        console.log("Password reset successfully");
        // You may want to refresh the list of representatives or update the UI accordingly
      })
      .catch((error) => {
        toast.error("Error resetting password. Please try again.");
        console.error("Error resetting password:", error);
      });
  };

  const filteredReps = reps
    .filter(
      (rep) =>
        rep.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        rep.rep_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        rep.NIC_Number.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      // Convert rep_id to numbers for proper numerical comparison
      const repIdA = parseInt(a.rep_id);
      const repIdB = parseInt(b.rep_id);
      return repIdA - repIdB; // Sort in ascending order
    });

  return (
    <div className="h-screen">
      <ToastContainer position="bottom-right" theme="dark" />
      <h2 className="text-xl font-semibold">Representatives</h2>
      <div className="mt-4">
        <div className="flex flex-row gap-4 w-full h-full">
          <input
            type="text"
            placeholder="Search Representatives"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="border border-gray-300 rounded-md p-2"
          />
          <div className={`${btnDisabled ? "hidden" : ""}`}>
            <button
              onClick={openModal}
              disabled={btnDisabled}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 flex items-center"
            >
              <FaPlus className="mr-2" />
              Add Rep
            </button>
          </div>
          {isModalOpen && (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <button
                  onClick={closeModal}
                  className="absolute top-0 right-0 p-2"
                >
                  <svg
                    className="h-6 w-6 text-gray-600"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>

                <AddRepForm onClose={closeModal} onAddRep={handleAddRep} />
              </div>
            </div>
          )}
          {isEditModalOpen && selectedRep && (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <button
                  onClick={closeEditModal}
                  className="absolute top-0 right-0 p-2"
                >
                  <svg
                    className="h-6 w-6 text-gray-600"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
                <EditRepForm
                  onClose={closeEditModal}
                  onEditRep={handleEditRep}
                  repData={selectedRep}
                  onResetPassword={handleResetPassword}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="overflow-auto h-4/6 mt-3 ">
        <table className="table-auto w-full border-collapse">
          <thead>
            <tr>
              <th
                className={`px-4 py-2 bg-gray-800 text-white text-center ${
                  !isModalOpen && !isEditModalOpen && "sticky top-0"
                }`}
              >
                RepNo
              </th>
              <th
                className={`px-4 py-2 bg-gray-800 text-white text-center ${
                  !isModalOpen && !isEditModalOpen && "sticky top-0"
                }`}
              >
                Name
              </th>
              <th
                className={`px-4 py-2 bg-gray-800 text-white text-center ${
                  !isModalOpen && !isEditModalOpen && "sticky top-0"
                }`}
              >
                Mobile Number
              </th>
              <th
                className={`px-4 py-2 bg-gray-800 text-white text-center ${
                  !isModalOpen && !isEditModalOpen && "sticky top-0"
                }`}
              >
                NIC Number
              </th>
              {!btnDisabled && (
                <th
                  className={`px-4 py-2 bg-gray-800 text-white text-center ${
                    !isModalOpen && !isEditModalOpen && "sticky top-0"
                  }`}
                >
                  Action
                </th>
              )}
            </tr>
          </thead>
          {loading ? (
            <SkeletonTable />
          ) : (
            <tbody>
              {filteredReps.map((rep) => (
                <tr key={rep.rep_id} className="hover:bg-gray-200">
                  <td className="border px-4 py-2 text-center">{rep.rep_id}</td>
                  <td className="border px-4 py-2 text-center">{rep.name}</td>
                  <td className="border px-4 py-2 text-center">
                    {rep.mobile_number}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {rep.NIC_Number}
                  </td>

                  {!btnDisabled && (
                    <td className="border px-4 py-2 text-center">
                      <button
                        onClick={() => openEditModal(rep)}
                        className="bg-purple-600 hover:bg-purple-700 text-white px-2 py-1 rounded-md mr-2"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(rep.rep_id)}
                        className="bg-red-600 hover:bg-red-700 text-white px-2 py-1 rounded-md mr-2"
                      >
                        Delete
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default RepsPage;
