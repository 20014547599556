import React, { useState } from "react";

const AddRepForm = ({ onClose, onAddRep }) => {
  const [repNo, setRepNo] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [nicNumber, setNicNumber] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddRep({ repNo, name, number, nicNumber });
    setRepNo("");
    setName("");
    setNumber("");
    setNicNumber("");
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md w-96">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Add Representative</h2>
        <button
          onClick={(e) => {
            e.stopPropagation(); // Prevent event propagation
            onClose(); // Close the dialog
          }}
          className="text-gray-600 hover:text-gray-800"
        >
          <svg
            className="h-6 w-6"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4 mt-8">
        <div>
          <label
            htmlFor="repNo"
            className="block text-md font-medium text-gray-700"
          >
            RepNo
          </label>
          <input
            type="text"
            id="repNo"
            value={repNo}
            onChange={(e) => setRepNo(e.target.value)}
            required
            className="pl-2 mt-3 pt-2 pb-2 block w-full  border-gray-300 rounded-md shadow-md  focus:outline-none border-b"
          />
        </div>
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="pl-2 mt-3 pt-2 pb-2 block w-full  border-gray-300 rounded-md shadow-md  focus:outline-none border-b"
          />
        </div>
        <div>
          <label
            htmlFor="number"
            className="block text-sm font-medium text-gray-700"
          >
            Number
          </label>
          <input
            type="number"
            id="number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            required
            className="pl-2 mt-3 pt-2 pb-2 block w-full  border-gray-300 rounded-md shadow-md focus:outline-none border-b"
          />
        </div>
        <div>
          <label
            htmlFor="nicNumber"
            className="block text-sm font-medium text-gray-700"
          >
            NIC Number
          </label>
          <input
            type="text"
            id="nicNumber"
            value={nicNumber}
            onChange={(e) => setNicNumber(e.target.value)}
            required
            className="pl-2 mt-3 pt-2 pb-2 block w-full  border-gray-300 rounded-md shadow-md  focus:outline-none border-b"
          />
        </div>
        <button
          type="submit"
          className="ml-32 bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 transition duration-300"
        >
          Add Rep
        </button>
      </form>
    </div>
  );
};

export default AddRepForm;
